<mat-spinner *ngIf="isLoading"></mat-spinner>

<div *ngIf="!isLoading">
  <nav class="navbar pt-4">
    <input
      name="serialNum"
      class="form-control"
      type="text"
      [(ngModel)]="serialNum"
      (ngModelChange)="Search()"
      placeholder="Írd be a sorszámot a kereséshez...">
  </nav>

  <div class="container-fluid pt-5" *ngFor="let voucher of vouchers">
    <div class="row justify-content-center mb-4">
      <div class="col-10 col-md-11 col-lg-10 col-xl-9">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div class="container-fluid">
              <div class="row">
                <div class="col-12 col-lg-6 col-xl-5">
                  <b>Sorszám:</b> &nbsp; {{voucher.id}}
                </div>
                <div *ngIf="biggerThanMediumScreen" class="col-lg-6 col-xl-3">
                  <b>Név:</b> &nbsp; {{voucher.firstName}} {{voucher.lastName}}
                </div>
                <div *ngIf="biggerThanLargeScreen" class="col-xl-4">
                  <b>E-mail:</b> &nbsp; {{voucher.email}}
                </div>
              </div>
            </div>
          </mat-expansion-panel-header>
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 col-md-6 col-xl-4">
                <b>Cím:</b> &nbsp; {{voucher.country}}, {{voucher.postcode}} {{voucher.city}}, {{voucher.address}}
              </div>
              <div class="col-12 col-md-6 col-xl-4">
                <b>Tel:</b> &nbsp; {{voucher.tel}}
              </div>
              <div *ngIf="!biggerThanMediumScreen" class="col-12 col-md-6 col-xl-4">
                <b>Név:</b> &nbsp; {{voucher.firstName}} {{voucher.lastName}}
              </div>
              <div *ngIf="!biggerThanLargeScreen" class="col-12 col-md-6 col-xl-4">
                <b>E-mail:</b> &nbsp; {{voucher.email}}
              </div>
              <div class="col-12 col-md-6 col-xl-4">
                <b>Éjszakák száma:</b> &nbsp; {{voucher.numOfNights}}
              </div>
              <div class="col-12 col-md-6 col-xl-4">
                <b>Hálószobák száma:</b> &nbsp; {{voucher.numOfBedrooms}}
              </div>
              <div class="col-12 col-md-6 col-xl-4">
                <b>Felnőttek száma:</b> &nbsp; {{voucher.numOfAdults}}
              </div>
              <div class="col-12 col-md-6 col-xl-4">
                <b>Gyerekek száma:</b> &nbsp; {{voucher.numOfChildren}}
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </div>

      <div class="col-2 col-md-1 d-flex justify-content-center align-items-center justify-content-around">
        <a [routerLink]="['/admin/vouchers/edit', voucher.id]">
          <img src="assets/icon/edit.svg">
        </a>
        <a class="delete" (click)="onDelete(voucher.id)">
          <img src="assets/icon/delete.svg">
        </a>
      </div>
    </div>
  </div>

  <a mat-raised-button color="primary" class="new-voucher-button" href="/admin/vouchers/new">Utalvány hozzáadása</a>
</div>

<mat-paginator
  class="paginator"
  *ngIf="vouchers.length > 0"
  [length]="totalVouchers"
  [pageSize]="vouchersPerPage"
  [pageSizeOptions]="pageSizeOptions"
  (page)="onChangedPage($event)">
</mat-paginator>
