import {Component, Inject, OnInit} from '@angular/core';

@Component({
  selector: 'app-popup-telephone-dialog',
  templateUrl: './popup-telephone-dialog.component.html',
  styleUrls: ['./popup-telephone-dialog.component.css']
})
export class PopupTelephoneDialogComponent implements OnInit {

  constructor() {}

  ngOnInit(): void {
  }

}
