<app-app-page-title [title]="pageTitle" [imgPath]="'assets/house/pageTitlePic.jpg'"></app-app-page-title>

<h1 class="pb-0 pr-3 pl-3 pt-4">Bejelentkezés és kijelentkezés</h1>

<div class="pb-3 pr-3 pl-3 pt-0">
  A  Sweet Farmot érkezés napján 15 órától lehet elfoglalni és elutazás napján 10 óráig kérjük elhagyni.  A tulajdonos a Farmot az érkezés napján 20 óráig tartja fenn, ezután jogosult annak értékesítésére. Eltérő igény esetén kérjük vegyék fel a kapcsolatot velünk.
  A foglalás benyújtásával a igényt leadó vendég elismeri, hogy a foglalási és lemondási feltételeket megismerte, és azokat elfogadja!
</div>

<h1 class="pb-0 pr-3 pl-3 pt-3">Lemondási feltételek</h1>

<div class="pb-3 pr-3 pl-3 pt-0">
  Foglalót nem kérünk, a lemondás ingyenes. Kérjük azonban a vendégeinket, hogy a lemondásukat minden esetben e-mailben legalább az érkezést megelőző 8 nappal korábban tegyék meg. Amennyiben a lemondást kiváltó ok ennél később keletkezik, kérjük azt a legrövidebb időn belül az elérhetőségeinken jelezni sziveskedjen.
</div>

<div class="pb-3 pr-3 pl-3 pt-0">
  A  Sweet Farmban nincs lehetőség a foglaláson feltüntetettnél több személy fogadására. Ebben az esetben a tulajdonos visszautasíthatja a foglalást, vagy különdíjat számol fel.
</div>
