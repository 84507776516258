<div class="container-fluid m-0 p-0">
  <div class="row justify-content-center m-0 p-0 mt-3">
    <h1 mat-dialog-title><b>Foglalás megerősítve!</b></h1>
  </div>

  <div class="row justify-content-center m-0 p-0 mt-4">
    <div class="col-10">
      <img src="assets/icon/reservation-confirmation.svg">
    </div>
  </div>

  <div class="row justify-content-center m-0 p-0 mt-4">
    <div class="col-10 information-text">
      Foglalásának részleteit elküldtük a megadott email címre.
    </div>
  </div>

  <div mat-dialog-actions class="row justify-content-center m-0 p-0 mt-4 mb-3">
    <div class="col-8">
      <button mat-dialog-close class="confirmation-button p-2">
        <span class="button-label pl-3 pr-3"><b>Rendben</b></span>
      </button>
    </div>
  </div>
</div>
