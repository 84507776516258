<app-app-page-title [title]="'Áraink'" [imgPath]="'assets/outside/5.jpg'"></app-app-page-title>

<div class="container-fluid prices-page-container m-0 p-0">
  <!--information text about the price-->
  <div class="row justify-content-center m-0 pb-3 pr-3 pl-3 pt-5">
    <div class="col-12 col-md-6 col-lg-5 col-xl-4 h-center p-0 m-0">
     <div class="price-text">
       Az ár egységesen <b>50.000</b> Forint naponta.
     </div>
     <div class="price-text pt-3">
       Maximum 4 embert tud fogadni a Sweet Farm egy időben.
     </div>
      <div class="price-text pt-3">
       Tehát, 1 felnőtt részére, vagy például 2 felnőtt és 2 gyermek részére egyaránt 50.000 Forintba kerül naponta, az év bármely napján, legyen az hétvége vagy ünnepnap.
     </div>
    </div>
  </div>

  <!--title of what the price contains-->
  <div class="row justify-content-center m-0 pb-3 pr-3 pl-3 pt-3">
    <div class="col-12 col-md-6 col-lg-5 col-xl-4 h-center p-0 m-0">
      <h1 class="title p-2">
        Áraink az alábbiakat tartalmazzák
      </h1>
    </div>
  </div>

  <!--items of what the price contains-->
  <div class="row justify-content-center m-0 pb-3 pr-3 pl-3 pt-3">
    <div class="col-12 col-md-6 col-lg-5 col-xl-4 p-0 m-0">
      <div class="row justify-content-center m-0 p-0">
        <div class="col-10 col-md-5 ml-lg-5 pl-0 pl-lg-2 pl-xl-3 pt-0 pb-0 pr-0">
          <li>
            Parkolás
          </li>
          <li>
            Grill platz használata
          </li>
          <li>
            Nyársbotok
          </li>
          <li>
            Grillrács
          </li>
          <li>
            Bogrács
          </li>
          <li>
            Tűzifa
          </li>
          <li>
            "Kukkolda" magasles
          </li>
          <li>
            "Grand bazár"
          </li>
          <li>
            "Loro bár"
          </li>
        </div>
        <div class="col-10 col-md-5 ml-2 pl-0 pl-lg-3 pl-xl-4 pr-0 pt-0 pb-0">
          <li>
            Függőágyak
          </li>
          <li>
            Nyugágyak
          </li>
          <li>
            Darts
          </li>
          <li>
            Szabadtéri játékok
          </li>
          <li>
            Higiéniai textíliák
          </li>
          <li>
            Törölköző
          </li>
          <li>
            Ágynemű
          </li>
          <li>
            Felszerelt konyha
          </li>
          <li>
            WiFi
          </li>
        </div>
      </div>
    </div>
  </div>

  <!--flower design to both sides-->
  <div class="row justify-content-between flowers-container m-0 p-0">
    <div class="col-4 image-container m-0 p-0">
      <img class="h-left" src="assets/orange-flower-right.png">
    </div>
    <div class="col-4 image-container h-right m-0 p-0">
      <img src="assets/orange-flower-left.png">
    </div>
  </div>
</div>
