<div class="container">
  <div class="gallery">
    <div *ngFor="let image of images; let i = index" class="image">
      <img [src]="image.path" (click)="selectedImage = i">
    </div>
  </div>
</div>

<app-images-carousel
  *ngIf="selectedImage !== null"
  (closeCarousel)="selectedImage = null"
  [images]="images"
  [selectedImage]="selectedImage">
</app-images-carousel>
