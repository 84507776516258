<nav class="navbar navbar-expand-lg navbar-dark sticky-top" #navbar [@navbar]="whiteState">

  <a class="navbar-brand" routerLink="/" (click)="onLogoClick()">
    <img #logo class="logo" src="../../../assets/white-green-reverse-logo-small.png"/>
  </a>

  <button #toggler class="navbar-toggler" (click)="onToggleClick()">
    <span #toggler_icon class="navbar-toggler-icon"></span>
  </button>

  <div #navbar_collapse class="collapse navbar-collapse" id="collapse_target">
    <span class="spacer"></span>
    <ul class="navbar-nav">
      <li class="nav-item" [@gallery]="menuState">
        <div class="nav-link-container">
          <a id="gallery" #gallery class="nav-link" routerLink="/gallery" routerLinkActive="active-link" (click)="onToggleClick()">GALÉRIA</a>
        </div>
      </li>
      <li class="nav-item" [@voucher]="menuState">
        <div class="nav-link-container">
          <a id="voucher" #voucher class="nav-link" routerLink="/voucher" routerLinkActive="active-link" (click)="onToggleClick()">UTALVÁNY</a>
        </div>
      </li>
      <li class="nav-item" [@price]="menuState">
        <div class="nav-link-container">
          <a id="prices" #price class="nav-link" routerLink="/prices" routerLinkActive="active-link" (click)="onToggleClick()">ÁRAINK</a>
        </div>
      </li>
      <li class="nav-item" [@faq]="menuState">
        <div class="nav-link-container">
          <a id="faq" #faq class="nav-link" routerLink="/questions" routerLinkActive="active-link" (click)="onToggleClick()">GYIK</a>
        </div>
      </li>
      <li class="nav-item" [@contact]="menuState">
        <div class="nav-link-container">
          <a id="contact" #contact class="nav-link" routerLink="/about-us" routerLinkActive="active-link" (click)="onToggleClick()">KAPCSOLAT</a>
        </div>
      </li>
      <li class="nav-item reservation" [@reservation]="menuState">
        <div class="nav-link-container">
          <a id="reservation" class="nav-link" routerLink="/reservation" routerLinkActive="active-link" (click)="onToggleClick()">FOGLALÁS</a>
        </div>
      </li>
      <li class="nav-item telephone-container">
        <span id="telephone" class="nav-link" (click)="openDialog()">
          <span class="helper"></span><img #telephone_icon class="telephone-icon" src="assets/icon/telephone.svg">
        </span>
      </li>
    </ul>
  </div>
</nav>

<div class="margin-for-other-components-below"></div>
