<div *ngIf="isLoaded">
  <mat-calendar
    class="cal-size"
    [dateClass]="dateClass"
    [dateFilter]="dateFilter"
    [headerComponent]="header"
    [startAt]="initialDate"
    (selectedChange)="onSelectedDateChange($event)">
  </mat-calendar>
</div>
