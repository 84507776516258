<!--number of adults-->
<div class="row justify-content-center m-0 p-3">
  <div class="col-12 col-md-6 col-lg-5 col-xl-4 p-0 m-0">
    <div class="row m-0 p-0">
      <div class="col-4 m-0 p-0">
        <label class="col-sm-2 col-form-label m-0 p-0" for="numOfAdults">Felnőttek:</label>
      </div>
      <select class="col-8 custom-select m-0 pl-2" id="numOfAdults" [(ngModel)]="booking.numOfAdults">
        <option value="1">1 Felnőtt</option>
        <option value="2">2 Felnőtt</option>
        <option value="3">3 Felnőtt</option>
        <option value="4">4 Felnőtt</option>
      </select>
    </div>
  </div>
</div>
<!--number of children-->
<div class="row justify-content-center m-0 pb-3 pr-3 pl-3 pt-0">
  <div class="col-12 col-md-6 col-lg-5 col-xl-4 p-0 m-0">
    <div class="row m-0 p-0">
      <div class="col-4 m-0 p-0">
        <label class="col-sm-2 col-form-label m-0 p-0" for="numOfChildren">Gyermekek:</label>
      </div>
      <select class="col-8 custom-select m-0 pl-2" id="numOfChildren" [(ngModel)]="booking.numOfChildren">
        <option value="0">0 Gyermek</option>
        <option value="1">1 Gyermek</option>
        <option value="2">2 Gyermek</option>
        <option value="3">3 Gyermek</option>
      </select>
    </div>
  </div>
</div>
<!--last name-->
<div class="row justify-content-center m-0 pb-3 pr-3 pl-3 pt-0">
  <div class="col-12 col-md-6 col-lg-5 col-xl-4 p-0 m-0">
    <div class="row m-0 p-0">
      <div class="col-4 m-0 p-0">
        <label class="col-sm-2 col-form-label m-0 p-0" for="lastName">Vezetéknév:</label>
      </div>
      <div class="col-8 m-0 p-0">
        <input
          #lastName
          placeholder="*"
          type="text"
          name="lastName"
          class="form-control"
          id="lastName"
          [(ngModel)]="booking.lastName"
          (input)="onInputTextFieldChange('lastName')"
          required>
      </div>
    </div>
  </div>
</div>
<!--first name-->
<div class="row justify-content-center m-0 pb-3 pr-3 pl-3 pt-0">
  <div class="col-12 col-md-6 col-lg-5 col-xl-4 p-0 m-0">
    <div class="row m-0 p-0">
      <div class="col-4 m-0 p-0">
        <span class="helper"></span><label class="col-sm-2 col-form-label m-0 p-0" for="firstName">Keresztnév:</label>
      </div>
      <div class="col-8 m-0 p-0">
        <input
          #firstName
          placeholder="*"
          name="firstName"
          type="text"
          class="form-control"
          id="firstName"
          [(ngModel)]="booking.firstName"
          (input)="onInputTextFieldChange('firstName')"
          required>
      </div>
    </div>
  </div>
</div>
<!--mobile number-->
<div class="row justify-content-center m-0 pb-3 pr-3 pl-3 pt-0">
  <div class="col-12 col-md-6 col-lg-5 col-xl-4 p-0 m-0">
    <div class="row m-0 p-0">
      <div class="col-4 m-0 p-0">
        <label class="col-sm-2 col-form-label m-0 p-0" for="tel">Telefonszám:</label>
      </div>
      <div class="col-8 m-0 p-0">
        <input
          #tel
          placeholder="*"
          name="tel"
          type="text"
          class="form-control"
          id="tel"
          [(ngModel)]="booking.tel"
          (input)="onInputTextFieldChange('tel')"
          required>
      </div>
    </div>
  </div>
</div>
<!--email address-->
<div class="row justify-content-center m-0 pb-3 pr-3 pl-3 pt-0">
  <div class="col-12 col-md-6 col-lg-5 col-xl-4 p-0 m-0">
    <div class="row m-0 p-0">
      <div class="col-4 m-0 p-0">
        <label class="col-sm-2 col-form-label m-0 p-0" for="email">Email:</label>
      </div>
      <div class="col-8 m-0 p-0">
        <input
          #email
          placeholder="*"
          name="email"
          type="email"
          class="form-control"
          id="email"
          [(ngModel)]="booking.email"
          (input)="onInputTextFieldChange('email')"
          required>
      </div>
    </div>
  </div>
</div>
<!--comment-->
<div class="row justify-content-center m-0 pb-3 pr-3 pl-3 pt-0">
  <div class="col-12 col-md-6 col-lg-5 col-xl-4 p-0 m-0">
    <div class="row m-0 p-0">
      <div class="col-4 m-0 p-0">
        <label for="comment" class="col-sm-2 col-form-label m-0 p-0">Megjegyzés:</label>
      </div>
      <div class="col-8 m-0 p-0">
        <textarea
          #comment
          name="comment"
          id="comment"
          class="form-control"
          [(ngModel)]="booking.comment"
          rows="2">
        </textarea>
      </div>
    </div>
  </div>
</div>
<!--check voucher-->
<div class="row justify-content-center m-0 pb-1 pr-3 pl-3 pt-0">
  <div class="col-12 col-md-6 col-lg-5 col-xl-4 p-0 m-0">
    <div class="row m-0 p-0">
      <div class="col-auto m-0 pl-1 pt-1">
        <mat-checkbox [color]="color" class="checkbox" id="voucher" name="voucher" (click)="onVoucherClick()"></mat-checkbox>
      </div>
      <div class="col-8 m-0 p-0">
        <label class="m-0 p-0" for="voucher">Van utalványom</label>
      </div>
    </div>
  </div>
</div>
<!--set voucher id-->
<div *ngIf="possessVoucher" class="row justify-content-center m-0 pb-3 pl-3 pr-3 pt-0">
  <div class="col-12 col-md-6 col-lg-5 col-xl-4 p-0 m-0">
    <div class="row m-0 p-0">
      <div class="col-3 m-0 p-0 voucher-id-label-container">
        <label class="voucher-id-label" for="voucherId">Sorszám:</label>
      </div>
      <div class="col-5 m-0 p-0">
        <input
          name="voucherId"
          id="voucherId"
          class="form-control"
          type="text"
          [ngModel]="booking?.voucherId">
      </div>
      <div class="col-1 m-0 p-0">
        <img *ngIf="alreadyCheckedVoucher && isVoucherValid" src="../../../../assets/icon/done.svg">
        <img *ngIf="alreadyCheckedVoucher && !isVoucherValid" src="../../../../assets/icon/wrong.svg">
      </div>
      <div class="col-3 m-0 p-0">
        <button class="next-button p-md-1" (click)="onCheckVoucher(f)">
          <span class="button-label"><b>Ellenőrzés</b></span>
        </button>
      </div>
    </div>
  </div>
</div>
<!--forward navigation button-->
<div class="row justify-content-center absolute-position m-0 p-3">
  <div class="col-12 col-md-6 col-lg-5 col-xl-4 p-0 m-0" (click)="onReservationPhaseChange(2)">
    <button class="next-button p-2">
      <span class="button-label pl-3 pr-3"><b>Folytatás</b></span>
    </button>
  </div>
</div>
