<!--from date-->
<div class="row justify-content-center m-0 pb-3 pr-4 pl-4 pt-3 pt-md-5">
  <div class="col-12 col-md-6 col-lg-5 col-xl-4 p-0 m-0">
    <div class="row m-0 p-0">
      <div class="col-4 m-0 p-0">
        <span class="col-sm-2 col-form-label m-0 p-0">Mettől:</span>
      </div>
      <div class="col-8 m-0 p-0 text-right col-form-label">{{fromDateString}}</div>
    </div>
  </div>
</div>
<!--to date-->
<div class="row justify-content-center m-0 pb-3 pr-4 pl-4 pt-0">
  <div class="col-12 col-md-6 col-lg-5 col-xl-4 p-0 m-0">
    <div class="row m-0 p-0">
      <div class="col-4 m-0 p-0">
        <span class="col-sm-2 col-form-label m-0 p-0">Meddig:</span>
      </div>
      <div class="col-8 m-0 p-0 text-right col-form-label">{{toDateString}}</div>
    </div>
  </div>
</div>
<!--number of adults-->
<div class="row justify-content-center m-0 pb-3 pr-4 pl-4 pt-0">
  <div class="col-12 col-md-6 col-lg-5 col-xl-4 p-0 m-0">
    <div class="row m-0 p-0">
      <div class="col-4 m-0 p-0">
        <span class="col-sm-2 col-form-label m-0 p-0">Felnőttek száma:</span>
      </div>
      <div class="col-8 m-0 p-0 text-right col-form-label">{{booking.numOfAdults}}</div>
    </div>
  </div>
</div>
<!--number of children-->
<div class="row justify-content-center m-0 pb-3 pr-4 pl-4 pt-0">
  <div class="col-12 col-md-6 col-lg-5 col-xl-4 p-0 m-0">
    <div class="row m-0 p-0">
      <div class="col-4 m-0 p-0">
        <span class="col-sm-2 col-form-label m-0 p-0">Gyerekek száma:</span>
      </div>
      <div class="col-8 m-0 p-0 text-right col-form-label">{{booking.numOfChildren}}</div>
    </div>
  </div>
</div>
<!--last name-->
<div class="row justify-content-center m-0 pb-3 pr-4 pl-4 pt-0">
  <div class="col-12 col-md-6 col-lg-5 col-xl-4 p-0 m-0">
    <div class="row m-0 p-0">
      <div class="col-4 m-0 p-0">
        <span class="col-sm-2 col-form-label m-0 p-0">Vezetéknév:</span>
      </div>
      <div class="col-8 m-0 p-0 text-right col-form-label">{{booking.lastName}}</div>
    </div>
  </div>
</div>
<!--first name-->
<div class="row justify-content-center m-0 pb-3 pr-4 pl-4 pt-0">
  <div class="col-12 col-md-6 col-lg-5 col-xl-4 p-0 m-0">
    <div class="row m-0 p-0">
      <div class="col-4 m-0 p-0">
        <span class="col-sm-2 col-form-label m-0 p-0">Keresztnév:</span>
      </div>
      <div class="col-8 m-0 p-0 text-right col-form-label">{{booking.firstName}}</div>
    </div>
  </div>
</div>
<!--mobile number-->
<div class="row justify-content-center m-0 pb-3 pr-4 pl-4 pt-0">
  <div class="col-12 col-md-6 col-lg-5 col-xl-4 p-0 m-0">
    <div class="row m-0 p-0">
      <div class="col-4 m-0 p-0">
        <span class="col-sm-2 col-form-label m-0 p-0">Telefonszám:</span>
      </div>
      <div class="col-8 m-0 p-0 text-right col-form-label">{{booking.tel}}</div>
    </div>
  </div>
</div>
<!--email address-->
<div class="row justify-content-center m-0 pb-3 pr-4 pl-4 pt-0">
  <div class="col-12 col-md-6 col-lg-5 col-xl-4 p-0 m-0">
    <div class="row m-0 p-0">
      <div class="col-4 m-0 p-0">
        <span class="col-sm-2 col-form-label m-0 p-0">Email:</span>
      </div>
      <div class="col-8 m-0 p-0 text-right col-form-label">{{booking.email}}</div>
    </div>
  </div>
</div>
<!--comment-->
<div class="row justify-content-center m-0 pb-3 pr-4 pl-4 pt-0">
  <div class="col-12 col-md-6 col-lg-5 col-xl-4 p-0 m-0">
    <div class="row m-0 p-0">
      <div class="col-4 m-0 p-0">
        <span class="col-sm-2 col-form-label m-0 p-0">Megjegyzés:</span>
      </div>
      <div class="col-8 m-0 p-0 text-right col-form-label">{{booking.comment}}</div>
    </div>
  </div>
</div>
<!--voucher id-->
<div *ngIf="booking.voucherId" class="row justify-content-center m-0 pb-3 pr-4 pl-4 pt-0">
  <div class="col-12 col-md-6 col-lg-5 col-xl-4 p-0 m-0">
    <div class="row m-0 p-0">
      <div class="col-4 m-0 p-0">
        <span class="col-sm-2 col-form-label m-0 p-0">Utalvány:</span>
      </div>
      <div class="col-8 m-0 p-0 text-right col-form-label">{{booking.voucherId}}</div>
    </div>
  </div>
</div>
<!--information text about the price-->
<div class="row justify-content-center price-text m-0 pb-3 pr-4 pl-4 pt-0">
  <div class="col-12 col-md-6 col-lg-5 col-xl-4 col-form-label p-0 m-0">
    A helyszínen fizetendő végösszeg: <b>{{finalPrice}}</b> HUF
  </div>
</div>
<!--accept terms & conditions-->
<div class="row justify-content-center terms-text m-0 pb-2 pr-4 pl-4 pt-0">
  <div class="col-12 col-md-6 col-lg-5 col-xl-4 p-0 m-0">
    A folytatással elfogadod az
    <a href="/terms-and-conditions" target="_blank">ÁSZF</a>
    -et és az
    <a href="https://greenparkpanzio.hu/adatvedelem/" target="_blank">Adatkezelési tájékoztatót.</a>
  </div>
</div>
<!--confirmation button-->
<div class="row justify-content-center absolute-position m-0 pt-0 pb-3 pr-3 pl-3">
  <div class="col-12 col-md-6 col-lg-5 col-xl-4 p-0 m-0">
    <button class="confirmation-button p-2" (click)="onSubmit()">
      <span class="button-label pl-3 pr-3"><b>Megerősítés</b></span>
    </button>
  </div>
</div>
