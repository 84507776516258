<div class="container mb-4 mt-2">
  <div class="row justify-content-center">
    <div class="col-auto">
      <ul class="nav nav-pills">
        <li class="nav-item">
          <a class="nav-link" [routerLink]="'/gallery/inside'" routerLinkActive="active-link">BELTÉR</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="'/gallery/outside'" routerLinkActive="active-link">KÜLTÉR</a>
        </li>
      </ul>
    </div>
  </div>
</div>
