<br>
<mat-spinner *ngIf="isLoading"></mat-spinner>
<br>
<form (ngSubmit)="onSubmit(f)" #f="ngForm" class="ml-5 mb-5" *ngIf="!isLoading">
  <div class="form-group row mb-5">
    <label class="col-sm-2 col-form-label" for="lastName">Vezetéknév: </label>
    <div class="col-6">
      <input type="text"
         name="lastName"
         class="form-control"
         id="lastName"
         [ngModel] = "voucher?.lastName"
         required
         placeholder="Nagy">
    </div>

    <label class="col-sm-2 col-form-label ml-4" for="numOfNights">Éjszakák száma:</label>
    <div class="col-1">
      <input
        type="number"
        name="numOfNights"
        min="1"
        step="1"
        class="form-control"
        [ngModel] = "voucher == null ? 2 : voucher.numOfNights"
        id="numOfNights"
        required>
    </div>
  </div>

  <div class="form-group row mb-5">
    <label class="col-sm-2 col-form-label" for="firstName">Keresztnév: </label>
    <div class="col-6">
      <input
        name="firstName"
        type="text"
        class="form-control"
        id="firstName"
        placeholder="János"
        [ngModel]="voucher?.firstName"
        required>
    </div>

    <label class="col-sm-2 col-form-label ml-4" for="numOfAdults">Felnőttek száma:</label>
    <div class="col-1">
      <input
        name="numOfAdults"
        type="number"
        min="1"
        max="6"
        step="1"
        class="form-control"
        id="numOfAdults"
        [ngModel]="voucher == null ? 2 : voucher.numOfAdults"
        required>
    </div>
  </div>

  <div class="form-group row mb-5">
    <label class="col-sm-2 col-form-label" for="tel">Telefonszám: </label>
    <div class="col-6">
      <input
        name="tel"
        type="text"
        class="form-control"
        id="tel"
        [ngModel]="voucher?.tel"
        placeholder="06201236547"
        required>
    </div>

    <label class="col-sm-2 col-form-label ml-4" for="numOfChildren">Gyerekek száma:</label>
    <div class="col-1">
      <input
        name="numOfChildren"
        type="number"
        min="0"
        step="1"
        max="4"
        class="form-control"
        id="numOfChildren"
        [ngModel]="voucher == null ? 0 : voucher.numOfChildren"
        required>
    </div>
  </div>

  <div class="form-group row mb-5">
    <label class="col-sm-2 col-form-label" for="email">E-mail cím: </label>
    <div class="col-6">
      <input
        name="email"
        type="text"
        class="form-control"
        id="email"
        placeholder="example@gmail.com"
        [ngModel]="voucher?.email"
        required>
    </div>
    <label class="col-sm-2 col-form-label ml-4" for="numOfBedrooms">Hálószobák száma:</label>
    <div class="col-1">
      <input
        name="numOfBedrooms"
        type="number"
        min="1"
        max="2"
        step="1"
        class="form-control"
        id="numOfBedRooms"
        [ngModel]="voucher == null ? 1 : voucher.numOfBedrooms"
        required>
    </div>
  </div>
  <div class="form-group row mb-5">
    <label class="col-1 col-form-label" for="country">Ország: </label>
    <div class="col-2">
      <input
        name="country"
        type="text"
        class="form-control"
        id="country"
        placeholder="Magyarország"
        [ngModel]="voucher?.country"
        required>
    </div>
    <label class="col-1 col-form-label" for="postcode">Irányítószám:</label>
    <div class="col-1">
      <input
        name="postcode"
        type="number"
        min="1"
        step="1"
        class="form-control"
        id="postcode"
        [ngModel]="voucher?.postcode"
        required>
    </div>
    <label class="col-1 col-form-label" for="city">Település neve:</label>
    <div class="col-2">
      <input
        name="city"
        type="text"
        class="form-control"
        id="city"
        [ngModel]="voucher?.city"
        required>
    </div>
    <label class="col-1 col-form-label pl-5" for="address">Cím:</label>
    <div class="col-3 pr-5">
      <input
        name="address"
        type="text"
        class="form-control"
        id="address"
        [ngModel]="voucher?.address"
        required>
    </div>
  </div>
  <div class="col-3 form-check" >
    <input
      name="isPaid"
      class="form-check-input"
      type="checkbox"
      [ngModel]="voucher?.isPaid"
      id="isPaid">
    <label class="form-check-label" for="isPaid">Fizetve</label>
  </div>
  <a mat-raised-button color="primary" class="btn-cancel" [routerLink]="'/admin/vouchers'">Vissza</a>
  <button mat-raised-button color="primary" type="submit" class="btn-save" [disabled]="!f.valid">Megerősítés</button>
</form>
