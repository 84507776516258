<div class="container">
  <div class="row justify-content-center mt-3">
    <div class="image-container col-10 col-md-6 col-lg-6 col-xl-5 p-3">
      <img src="../../../../../assets/house/house01.jpg">
    </div>
    <div class="col-10 col-md-4 col-lg-4 col-xl-4 p-3 description-container">
      <h2>Ajándékutalvány díszdobozban</h2>
      <p>Lepje meg szeretteit egy ilyen nagyon nagyon jó ajándékutalvánnyal stb stb ez húúú de szuper meg nagyon jó.</p>
      <ul>
        <li>A díszdobozért és csomagolásért plusz díjat nem számolunk fel</li>
        <li>Postázás költségeit is mi álljuk</li>
      </ul>
      <a class="btn btn-primary m-3" [routerLink]="'/voucher/reservation'">Tovább</a>
    </div>
  </div>
</div>
