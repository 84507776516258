<div class="container-fluid reservation-form-container m-0 p-0">
  <!--stepper navigation bar-->
  <div class="row justify-content-center m-0 p-0">
    <div class="col-12 col-md-6 col-lg-5 col-xl-4 p-0 m-0">
      <app-reservation-form-stepper></app-reservation-form-stepper>
    </div>
  </div>
  <!--reservation form steps pages-->
  <div class="container-fluid reservation-steps-container m-0 p-0 pt-4">
    <app-reservation-form-date *ngIf="phaseValue == 0"></app-reservation-form-date>
    <app-reservation-form-data *ngIf="phaseValue == 1"></app-reservation-form-data>
    <app-reservation-form-summary *ngIf="phaseValue == 2"></app-reservation-form-summary>
  </div>
  <!--flower design to both sides-->
  <div class="row justify-content-between flowers-container m-0 p-0">
    <div class="col-4 image-container m-0 p-0">
      <img class="h-left" src="assets/orange-flower-right.png">
    </div>
    <div class="col-4 image-container h-right m-0 p-0">
      <img src="assets/orange-flower-left.png">
    </div>
  </div>
</div>
