<app-app-page-title [title]="'Gyakori kérdések'" [imgPath]="'assets/outside/11.jpeg'"></app-app-page-title>

<div class="container-fluid pt-5 pb-5">
  <div *ngFor="let faq of FAQs">
    <div class="row justify-content-center p-0 m-0">
      <div class="col-11 col-sm-10 col-md-9 col-lg-8 col-xl-7 p-2">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            {{faq.question}}
          </mat-expansion-panel-header>
          <div class="answer">
            {{faq.answer}}
          </div>
        </mat-expansion-panel>
      </div>
    </div>
  </div>
</div>

