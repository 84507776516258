<div mat-dialog-content>
  <a href="tel:+36703765751">
    +36703765751
  </a>
</div>
<div mat-dialog-content>
  <a href="mailto: info@sweetfarm.hu">
    info@sweetfarm.hu
  </a>
</div>
