<app-app-page-title [title]="'Kapcsolat'" [imgPath]="'assets/outside/13.jpeg'"></app-app-page-title>

<h1>
  Elérhetőségeink:
</h1>

<div class="container m-0 p-0">
  <div class="row m-0 p-0">
    <div class="col-6 col-sm-4 col-md-3 col-xl-2 pl-3 pl-md-4 pl-lg-5 label">
      E-mail címünk:
    </div>
    <div class="col-auto pl-2">
      <a href="mailto: info@sweetfarm.hu">info@sweetfarm.hu</a>
    </div>
  </div>
  <div class="row m-0 pb-0 pr-0 pl-0 pt-2">
    <div class="col-6 col-sm-4 col-md-3 col-xl-2 pl-3 pl-md-4 pl-lg-5 label">
      Telefonszámunk:
    </div>
    <div class="col-auto pl-2">
      <a href="tel:+36703765751">+3670-376-57-51</a>
    </div>
  </div>
</div>

<h1>
  Elhelyezkedés:
</h1>

<div class="label pl-3 pl-md-4 pl-lg-5 pr-3 pr-md-4 pr-lg-5 pb-3 pt-3">
  Eger határától körülbelül másfél kilóméterre található egy csendes kis dombocskán, közel a természethez, csodálatos kilátással a városra.
  A Sweet Farm-ig egész évben és időjárástól függetlenül járható aszfaltozott "tipikus" rossz minőségű magyar út vezet az utolsó egy kilóméteren.
</div>

<div class="container m-0 p-0 pb-3">
  <div class="row m-0 p-0">
    <div class="col-6 col-sm-4 col-md-3 col-xl-2 pl-3 pl-md-4 pl-lg-5 label">
      Pontos helyszín:
    </div>
    <div class="col-auto pl-2">
      <a href="https://maps.app.goo.gl/dpztkSDJ4AfKVV729" target="_blank" class="orange">ITT</a>
    </div>
  </div>
</div>

<iframe
  class="googlemaps"
  src="https://maps.google.com/maps?q=47.880152,20.362495&hl=es;z=14&amp;output=embed" allowfullscreen>
</iframe>
