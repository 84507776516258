<div #stepperContainer class="container-fluid m-0 p-0">
  <div class="row justify-content-center m-0 p-0">
    <div class="col-8 p-0">
      <mat-slider class="p-0" min="0" max="2" step="1" [disabled]="true" [value]="phaseValue"></mat-slider>
    </div>
  </div>
  <div class="row justify-content-center m-0 p-0">
    <div #dateDiv class="col-4 h-center m-0 pl-2 pr-0" (click)="onTabClick(0)">
      <b>Dátum</b>
    </div>
    <div #dataDiv class="col-4 h-center m-0 p-0" (click)="onTabClick(1)">
      <b>Adatok</b>
    </div>
    <div #summaryDiv class="col-4 h-center m-0 pr-2 pl-0" (click)="onTabClick(2)">
      <b>Összegzés</b>
    </div>
  </div>
</div>
