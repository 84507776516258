<br>
<mat-spinner *ngIf="isLoading"></mat-spinner>
<br>

<form (ngSubmit)="onSubmit(f)" #f="ngForm" *ngIf="!isLoading">
  <div class="container">
    <div class="row p-3">
      <div class="col-2">
        <a class="btn btn-primary" [routerLink]="'/voucher/information'">Vissza</a>
      </div>
    </div>

    <div class="row justify-content-center">
      <!--last name-->
      <div class="col-4 col-md-3 col-lg-1 pt-3">
        <label class="col-sm-2 col-form-label" for="lastName">Vezetéknév: </label>
      </div>
      <div class="col-8 col-md-7 col-lg-3 pt-3">
        <input type="text"
               name="lastName"
               class="form-control"
               id="lastName"
               [ngModel] = "voucher?.lastName"
               required>
      </div>
      <!--first name-->
      <div class="col-4 col-md-3 col-lg-1 pt-3">
        <label class="col-sm-2 col-form-label" for="firstName">Keresztnév: </label>
      </div>
      <div class="col-8 col-md-7 col-lg-3 pt-3">
        <input
          name="firstName"
          type="text"
          class="form-control"
          id="firstName"
          [ngModel]="voucher?.firstName"
          required>
      </div>
    </div>
    <div class="row justify-content-center">
      <!--mobile number-->
      <div class="col-4 col-md-3 col-lg-1 pt-3">
        <label class="col-sm-2 col-form-label" for="tel">Telefonszám: </label>
      </div>
      <div class="col-8 col-md-7 col-lg-3 pt-3">
        <input
          name="tel"
          type="text"
          class="form-control"
          id="tel"
          [ngModel]="voucher?.tel"
          required>
      </div>
      <!--email address-->
      <div class="col-4 col-md-3 col-lg-1 pt-3">
        <label class="col-sm-2 col-form-label" for="email">Emailcím: </label>
      </div>
      <div class="col-8 col-md-7 col-lg-3 pt-3">
        <input
          name="email"
          type="email"
          class="form-control"
          id="email"
          [ngModel]="voucher?.email"
          required>
      </div>
    </div>
    <div class="row justify-content-center">
      <!--number of nights-->
      <div class="col-4 col-md-3 col-lg-1 pt-3">
        <label class="col-sm-2 col-form-label" for="numOfNights">Éjszakák száma:</label>
      </div>
      <div class="col-2 col-md-2 col-lg-1 pt-3">
        <input
          type="number"
          name="numOfNights"
          min="1"
          step="1"
          class="form-control"
          [ngModel] = "voucher == null ? 2 : voucher.numOfNights"
          id="numOfNights"
          required>
      </div>
      <div class="col-6 col-md-5 col-lg-2"></div>
      <!--number of bedrooms-->
      <div class="col-4 col-md-3 col-lg-1 pt-3">
        <label class="col-sm-2 col-form-label" for="numOfBedrooms">Hálószobák száma:</label>
      </div>
      <div class="col-2 col-md-2 col-lg-1 pt-3">
        <input
          name="numOfBedrooms"
          type="number"
          min="1"
          max="2"
          step="1"
          class="form-control"
          id="numOfBedRooms"
          [ngModel]="voucher == null ? 1 : voucher.numOfBedrooms"
          required>
      </div>
      <div class="col-6 col-md-5 col-lg-2"></div>
    </div>
    <div class="row justify-content-center">
      <!--number of adults-->
      <div class="col-4 col-md-3 col-lg-1 pt-3">
        <label class="col-sm-2 col-form-label" for="numOfAdults">Felnőttek száma:</label>
      </div>
      <div class="col-2 col-md-2 col-lg-1 pt-3">
        <input
          name="numOfAdults"
          type="number"
          min="1"
          max="6"
          step="1"
          class="form-control"
          id="numOfAdults"
          [ngModel]="voucher == null ? 2 : voucher.numOfAdults"
          required>
      </div>
      <div class="col-6 col-md-5 col-lg-2"></div>
      <!--number of children-->
      <div class="col-4 col-md-3 col-lg-1 pt-3">
        <label class="col-sm-2 col-form-label" for="numOfChildren">Gyerekek száma:</label>
      </div>
      <div class="col-2 col-md-2 col-lg-1 pt-3">
        <input
          name="numOfChildren"
          type="number"
          min="0"
          step="1"
          max="4"
          class="form-control"
          id="numOfChildren"
          [ngModel]="voucher == null ? 0 : voucher.numOfChildren"
          required>
      </div>
      <div class="col-6 col-md-5 col-lg-2"></div>
    </div>
    <div class="row justify-content-center">
      <!--country-->
      <div class="col-4 col-md-3 col-lg-1 pt-3">
        <label class="col-1 col-form-label" for="country">Ország:</label>
      </div>
      <div class="col-8 col-md-7 col-lg-3 pt-3">
        <input
          name="country"
          type="text"
          class="form-control"
          id="country"
          placeholder="Magyarország"
          [ngModel]="voucher?.country"
          required>
      </div>
      <!--post code-->
      <div class="col-4 col-md-3 col-lg-1 pt-3">
        <label class="col-1 col-form-label" for="postcode">Irányítószám:</label>
      </div>
      <div class="col-8 col-md-7 col-lg-3 pt-3">
        <input
          name="postcode"
          type="number"
          min="1"
          step="1"
          class="form-control"
          id="postcode"
          placeholder="3300"
          [ngModel]="voucher?.postcode"
          required>
      </div>
    </div>
    <div class="row justify-content-center pb-5">
      <!--town-->
      <div class="col-4 col-md-3 col-lg-1 pt-3">
        <label class="col-1 col-form-label" for="city">Település neve:</label>
      </div>
      <div class="col-8 col-md-7 col-lg-3 pt-3">
        <input
          name="city"
          type="text"
          class="form-control"
          id="city"
          placeholder="Eger"
          [ngModel]="voucher?.city"
          required>
      </div>
      <!--address-->
      <div class="col-4 col-md-3 col-lg-1 pt-3">
        <label class="col-1 col-form-label" for="address">Cím:</label>
      </div>
      <div class="col-8 col-md-7 col-lg-3 pt-3">
        <input
          name="address"
          type="text"
          class="form-control"
          id="address"
          placeholder="Attila utca 15."
          [ngModel]="voucher?.address"
          required>
      </div>
    </div>
  </div>
  <div class="row justify-content-end pb-4">
    <div class="col-3">
      <button mat-raised-button color="primary" type="submit" [disabled]="!f.valid">Megerősítés</button>
    </div>
  </div>
</form>

