<img class="mb-4 pt-3" src="assets/icon/admin.svg">
<mat-card class="ml-5 mr-5">
  <mat-spinner *ngIf="isLoading"></mat-spinner>
  <form (submit)="onLogin(loginForm)" #loginForm="ngForm" *ngIf="!isLoading">
    <mat-form-field>
      <input matInput name="email" ngModel type="email" placeholder="E-Mail" #emailInput="ngModel" required email>
      <mat-error *ngIf="emailInput.invalid">Please enter a valid email.</mat-error>
    </mat-form-field>
    <mat-form-field>
      <input type="password" name="password" ngModel matInput placeholder="Password" #passwordInput="ngModel" required>
      <mat-error *ngIf="passwordInput.invalid">Please enter a valid password.</mat-error>
    </mat-form-field>
    <button mat-raised-button color="accent" type="submit" *ngIf="!isLoading">Login</button>
  </form>
</mat-card>
